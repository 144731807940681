import { useRef, useEffect, useState, useCallback } from 'react';

function get(object, path, defaultValue) {
  const pathArr = Array.isArray(path) ? path : path.split('.');
  let index = 0;
  const length = pathArr.length;
  while (object != null && index < length) {
    object = object[pathArr[index++]];
  }
  return index && index === length && object ? object : defaultValue;
}

function guid() {
  const crypto = window.crypto;
  if (crypto) {
    const newNumber = [1e7, 1e3, 4e3, 8e3, 1e11];
    const newString = newNumber.join('-');
    return newString.replace(/[018]/g, c => cryptoGenerateString(c));
  }
  return '';
}
function cryptoGenerateString(c) {
  const convertedString = Number(c);
  const newCryptoNumber = convertedString ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> convertedString / 4;
  return newCryptoNumber.toString(16);
}

function isEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

function merge(target, source1, source2) {
  return Object.assign(target, source1, source2);
}

function noop() {
  //do nothing
}

function pick(obj, params) {
  const result = {};
  for (const param of params) {
    if (obj[param]) {
      result[param] = JSON.parse(JSON.stringify(obj[param]));
    }
  }
  return result;
}

function slice(arr, start, end) {
  return [...arr].slice(start, end);
}

function uniq(arr) {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

/**
 * a custom hooks for our setInterval operations
 * get more info in Dan Abramov's article https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param callback - callback function that will be called every `delay` ms.
 * @param delay - number representing the delay in ms. Set to `null` to "pause" the interval.
 */
const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback && savedCallback.current && savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const getSortName = name => {
  const initials = name.match(/\b\w/g) || [];
  if (initials.length > 1) {
    const results = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return results;
  }
  return name.substr(0, 2).toUpperCase();
};
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

// convert bytes to KB, MB, GB format
function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(getBaseLog(k, bytes));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}
// getBaseLog(2,8) return 3 since 2 x 2 x 2 = 8
function getBaseLog(x, y) {
  return Math.log(y) / Math.log(x);
}

const getUniqueID = () => Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 7);

/***
 * a custom hook that allows a click handler to differentiate between single click and double click
 * @param onClick {Function} - a callback function with signature (isDoubleClick: boolean, clickTarget: T) => void
 * @param clickIntervalMs {Number} -
 * @returns {Function} - a handler function to trigger when an item is clicked
 */
const useDoubleClick = (onClick, clickIntervalMs = 500) => {
  const [clickCount, setClickCount] = useState(0);
  const [clickTarget, setClickTarget] = useState();
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (clickCount === 1) {
        onClick(false, clickTarget);
        setClickCount(0);
      }
    }, clickIntervalMs);
    if (clickCount === 2) {
      onClick(true, clickTarget);
      setClickCount(0);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [onClick, clickIntervalMs, clickCount, clickTarget]);
  return useCallback(targetItem => {
    setClickCount(previousCount => previousCount + 1);
    setClickTarget(targetItem);
  }, []);
};

const useBackupImg = (src, backupSrc) => {
  const [imgSrc, setImgSrc] = useState(src || backupSrc);
  const handleError = () => setImgSrc(backupSrc);
  useEffect(() => {
    setImgSrc(src || backupSrc);
  }, [src, backupSrc]);
  return [imgSrc, handleError];
};

function getElement(elementId, widgetName) {
  var _a, _b;
  let element = document.getElementById(elementId);
  if (element) return element;else {
    element = document.createElement('div');
    element.id = elementId;
    if (widgetName === 'APP_BAR') {
      (_a = document.getElementsByTagName('body').item(0)) === null || _a === void 0 ? void 0 : _a.prepend(element);
    } else {
      // appending the panel layers the newest panels on top of older mounted ones
      (_b = document.getElementsByTagName('body').item(0)) === null || _b === void 0 ? void 0 : _b.append(element);
    }
    return element;
  }
}

export { formatBytes, get, getElement, getRandomInt, getSortName, getUniqueID, guid, isEqual, merge, noop, pick, slice, uniq, useBackupImg, useDoubleClick, useInterval };
